
import { Component, Mixins, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import ReportButtonComponent from '@/components/Reports/ReportButtonComponent.vue';
import { reportTypes, latestReportType, latestType } from './types';
import Routes from '@/router/Routes';
import FetchMixin from '@/mixins/FetchMixin';
import { DcrImportLog } from '@/models/Entities/Imports/DcrImportLog';
import { TlnImportLog } from '@/models/Entities/Imports/TlnImportLog';
import { formatDate } from '@/services/formatService';
import { IModel } from '@/store/crudModule/ICrudModule';

interface Call {
  path: string;
  name: string;
  model: IModel<latestType>;
}

@Component<ReportDashboardPage>({
  components: { ButtonComponent, IconComponent, ReportButtonComponent }
})
export default class ReportDashboardPage extends Mixins(Vue, FetchMixin) {
  latestDateTime: latestReportType = {
    dcr: '',
    tln: ''
  };
  latestCalls: Call[] = [
    {
      path: 'dcr-import-log',
      name: 'dcr',
      model: DcrImportLog
    },
    {
      path: 'tln-import-log',
      name: 'tln',
      model: TlnImportLog
    }
  ];

  get reportButtons(): reportTypes[] {
    return [
      {
        title: 'Grad Report Builder',
        subtext: '',
        icon: 'graduateCap',
        link: () => void 0
      },
      {
        title: 'Gains Priority',
        subtext: '',
        icon: 'arrowDownThreeBars',
        link: () => {
          this.$router.push({
            name: Routes.GAINS_PRIORITY
          });
        }
      },
      {
        title: 'Gained - No TLN',
        subtext: '',
        icon: 'calendarImportant',
        link: () => void 0
      },
      {
        title: 'Orders Ending',
        subtext: '',
        icon: 'sandTimer',
        link: () => {
          this.$router.push({
            name: Routes.ORDERS_ENDING
          });
        }
      },
      {
        title: 'UPT Date Arrived Station',
        subtext: '',
        icon: 'calendarStar',
        link: () => void 0
      },
      {
        title: 'Inpro Roster',
        subtext: '',
        icon: 'checkList',
        link: () => {
          this.$router.push({
            name: Routes.INPRO_CLASSES,
            params: { fromReports: 'true' }
          });
        }
      },
      {
        title: 'OTS Roster',
        subtext: '',
        icon: 'checkList',
        link: () => {
          this.$router.push({
            name: Routes.OTS_CLASSES,
            params: { fromReports: 'true' }
          });
        }
      },
      {
        title: 'DCR Imports',
        subtext: this.latestDateTime.dcr,
        icon: 'clipboardArrowRight',
        link: () => {
          this.$router.push({
            name: Routes.VIEW_DCR
          });
        }
      },
      {
        title: 'TLN Imports',
        subtext: this.latestDateTime.tln,
        icon: 'clipboardArrowRight',
        link: () => {
          this.$router.push({
            name: Routes.TLN_IMPORT_REPORT
          });
        }
      }
    ];
  }

  goToDashboard(): void {
    this.$router.push({ name: Routes.STUDENT_DASHBOARD });
  }
  async created(): Promise<void> {
    await this.queryImportDateTime();
  }
  async queryImportDateTime(): Promise<void> {
    await Promise.all(
      this.latestCalls.map(async ({ path, model, name }) => {
        const createDateTime = await this.getLatest(path as string, model);
        if (createDateTime) {
          const latest = formatDate(createDateTime);
          this.latestDateTime[
            name as keyof latestReportType
          ] = `Updated ${latest}`;
        }
      })
    );
  }
}
