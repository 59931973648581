var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "px-16 pt-8 pb-12 bg-gray-100" }, [
    _c("div", { staticClass: "flex items-center mb-14" }, [
      _c("h3", [_vm._v("Reports")]),
      _c(
        "div",
        { staticClass: "flex ml-auto" },
        [
          _c(
            "button-component",
            {
              attrs: { bg: "val-button-blue", textOrBorderColor: "white" },
              on: {
                click: function ($event) {
                  return _vm.goToDashboard()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex items-center" },
                [
                  _c("icon-component", {
                    staticClass: "mr-2",
                    attrs: { name: "grid", height: 14, width: 14 },
                  }),
                  _vm._v(" Dashboard "),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass:
          "grid gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6",
      },
      _vm._l(_vm.reportButtons, function (report, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("report-button-component", {
              attrs: {
                title: report.title,
                subtext: report.subtext,
                icon: report.icon,
              },
              on: {
                click: function ($event) {
                  return report.link()
                },
              },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }