import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { plainToClass, Transform, Type } from 'class-transformer';
import { TlnRowLog } from './TlnRowLog';

export class TlnImportLog {
  @Type(/* istanbul ignore next */ () => Number)
  id!: number;

  @Type(/* istanbul ignore next */ () => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  deletedAt?: Date;

  @Type(/* istanbul ignore next */ () => Date)
  createDateTime!: Date;

  @Type(/* istanbul ignore next */ () => Number)
  createdBy?: number | null;

  @Type(/* istanbul ignore next */ () => Number)
  totalErrors!: number;

  @Type(/* istanbul ignore next */ () => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  lastChangedDateTime!: Date;

  @Type(/* istanbul ignore next */ () => Number)
  lastChangedBy?: number | null;
  importedData!: TlnRowLog[];

  constructor(partial?: Partial<TlnImportLog>) {
    if (partial) {
      Object.assign(this, plainToClass(TlnImportLog, partial));
    }
  }
}
