import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { latestType } from '@/pages/340th/Reports/types';
import { Vue, Component } from 'vue-property-decorator';
import {
  SearchDto,
  SearchSortDirection
} from '../models/Dtos/common/searchDto';
import { IModel } from '../store/crudModule/ICrudModule';

@Component
export default class FetchMixin extends Vue {
  async getLatest<T extends latestType>(
    path: string,
    model: IModel<T>
  ): Promise<Date | null> {
    const searchDto: SearchDto<T, never> = {
      limit: 1,
      offset: 0,
      sortProperty: 'createDateTime',
      sortDirection: SearchSortDirection.DESCENDING
    };
    const importLatestResult: SearchResponseDto<T> = await this.$store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      {
        path: path,
        query: searchDto,
        model: model
      }
    );
    return importLatestResult.data.length
      ? importLatestResult.data[0].createDateTime
      : null;
  }

  async getOne<T, SearchDtoType extends SearchDto<T, never>>(payload: {
    path: string;
    query: SearchDtoType;
    model: IModel<T>;
  }): Promise<T> {
    const searchDto: SearchDtoType = {
      ...payload.query,
      limit: 1,
      offset: 0,
      sortProperty: 'id',
      sortDirection: SearchSortDirection.ASCENDING
    };
    const importResult = await this.$store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      {
        path: payload.path,
        query: searchDto,
        model: payload.model
      }
    );
    return importResult.data.length ? importResult.data[0] : importResult.data;
  }
}
