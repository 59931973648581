import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { Type, Transform, plainToClass } from 'class-transformer';

export class DcrImportLog {
  @Type(/* istanbul ignore next */ () => Number)
  id!: number;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  dateCertified!: Date | null;
  @Type(/* istanbul ignore next */ () => Date)
  createDateTime!: Date;
  @Type(/* istanbul ignore next */ () => Number)
  totalSaved!: number;
  @Type(/* istanbul ignore next */ () => Number)
  totalErrors!: number;

  constructor(partial?: Partial<DcrImportLog>) {
    if (partial) {
      Object.assign(this, plainToClass(DcrImportLog, partial));
    }
  }
}
