export class SearchDto<EntityType, CustomSortType> {
  limit?: number;
  offset?: number;
  relations?: (keyof EntityType)[];
  sortProperty?: keyof EntityType | CustomSortType;
  sortDirection?: SearchSortDirection = SearchSortDirection.ASCENDING;
}

export enum SearchSortDirection {
  ASCENDING = 1,
  DESCENDING = -1
}
