var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "group" }, [
    _c(
      "div",
      {
        staticClass:
          "border-2 border-gray-200 rounded-lg w-252 h-96 bg-white flex justify-center items-center flex-col cursor-pointer hover:bg-val-button-blue group-hover:text-white",
        on: {
          click: _vm.clickHandler,
          mouseover: function ($event) {
            _vm.hover = true
          },
          mouseleave: function ($event) {
            _vm.hover = false
          },
        },
      },
      [
        _c("icon-component", {
          attrs: {
            width: 30,
            height: 25,
            name: _vm.icon,
            fill: _vm.hover ? "#ffffff" : "#3E66FB",
          },
        }),
        _c("h5", { staticClass: "mt-1.5" }, [_vm._v(_vm._s(_vm.title))]),
        _c(
          "h6",
          {
            staticClass:
              "text-tiny font-lato text-gray-400 group-hover:text-white",
          },
          [_vm._v(" " + _vm._s(_vm.subtext) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }