
import Routes from '@/router/Routes';
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from '../IconComponent.vue';

@Component<ReportButtonComponent>({
  components: {
    IconComponent
  }
})
export default class ReportButtonComponent extends Vue {
  @Prop({ default: '' })
  title!: string;

  @Prop({ default: '' })
  subtext!: string;

  @Prop({ default: null })
  icon!: string;

  @Prop()
  link?: Routes;

  hover = false;

  clickHandler(evt: Event): void {
    this.$emit('click', evt);
  }
}
